var clase, total, aux, pos;
aux = true;
total = $(".pestanas article").length;
$(window).resize(function () {
    if ($(window).width() <= 991 && aux == true) {
        aux = false;
        $(".indice").hide();
        $(".pestanas article").show();
        $(".pestanas article").css("position", "relative");
        for (i = 0; i < total; i++) {

            clase = jQuery(".pestanas article").eq(i).attr("class");
            clase = clase.split(" ")[1];
            clase = clase.replace(/_/g, " ");

            $(".pestanas article").eq(i).prepend("<h4>" + clase + "</h4>").append();
            $(".pestanas article").eq(i).find("h4").css("text-transform", "capitalize");
            $(".pestanas article").eq(i).append("<hr/>");

        }


    } else if ($(window).width() > 991) {
        $(".pestanas article").find("h4").remove();
        $(".pestanas article").find("hr").remove();
        $(".indice").show();
        $(".pestanas article").hide();
        $(".pestanas article").first().show();
        $(".pestanas article").css("position", "absolute");
        aux = true;
    }
});

$(".indice li a").on("click", function () {
    $(".indice li").removeClass();
    clase = $(this).attr("class");
    $(this).parent().addClass("active");
    $(".pestanas article").fadeOut();
    $("." + clase + "").fadeIn();
    var height = jQuery("article." + clase + "").height();
    $('.pestanas').css('min-height', height);


});


if ($(window).width() > 991) {
    $(".pestanas article").find("h4").remove();
    $(".pestanas article").find("hr").remove();
    $(".indice").show();
    $(".pestanas article").hide();
    $(".pestanas article").first().show();


} else if ($(window).width() <= 991 && aux == true) {
    aux = false;
    $(".indice").hide();
    $(".pestanas article").show();
    $(".pestanas article").css("position", "relative");
    for (i = 0; i < total; i++) {

        clase = jQuery(".pestanas article").eq(i).attr("class");
        clase = clase.split(" ")[1];
        clase = clase.replace(/_/g, " ");

        $(".pestanas article").eq(i).prepend("<h4>" + clase + "</h4>").append();
        $(".pestanas article").eq(i).find("h4").css("text-transform", "capitalize");
        $(".pestanas article").eq(i).append("<hr/>");

    }
}